!((document, $) => {
  'use strict';

  /**
   * Moves the "My Account" menu item into the main hamburger menu`
   * when the screen width is 768px or less (mobile mode).
   */
  function moveMyAccount() {
    if ($(window).width() <= 768) {
      if ($('.block-system-user-menu').length) {
        $('.block-system-user-menu')
          .find('a')
          .each(function() {
            if ($.trim($(this).text()) === 'My account') {
              let parentLi = $(this).closest('li');
              $('.block-system-main-menu .menu-dropdown').append(parentLi);
            }
          });
      }
    }
  }

  $(document).ready(function() {
    var pathname = window.location.pathname;
    if (pathname === '/cart') {
      $('.page-title').text('My Cart');

      // change cart page table header from "Products" to "Selection"
      $('#uc-cart-view-form thead th').each(function() {
        if (
          $(this)
            .text()
            .trim()
            .toLowerCase() === 'products'
        ) {
          $(this).text('Selection');
        }
      });
    }

    if ($('.node-type-event').length) {
      $('.node-add-to-cart').click(function() {
        console.log('first');
        let buttonID = $(this).attr('class');
        localStorage.setItem('lastClickedButton', buttonID);
      });

      let lastClicked = localStorage.getItem('lastClickedButton');
      if (lastClicked && lastClicked.includes('node-add-to-cart')) {
        $('html, body').scrollTop($('.block-system-messages').offset().top);
        localStorage.removeItem('lastClickedButton');
      }
    }

    moveMyAccount();

    // Scroll to tickets section if the URL has a scroll=tickets query parameter
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('scroll') === 'tickets') {
      const ticketsSection = document.querySelector('.tickets');
      if (ticketsSection) {
        ticketsSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  $(window).resize(function() {
    moveMyAccount();
  });
})(document, jQuery);
